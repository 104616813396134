import axiosInstance from "api/api-service";
import { RETAILERS } from "constants/api-endpoints";
import { HttpResponse } from "models/http-interface";
import { ListParams } from "models/retailer-interface";

/**
 * @desc: create retailer
 * @param body
 * @returns
 */
export const createRetailer = (body: FormData) => {
  return axiosInstance.post<HttpResponse<null>>(RETAILERS.RETAILERS, body);
};

/**
 * @desc: delete retailer
 * @param retailer_id
 * @returns
 */
export const deleteRetailer = (retailer_id: string) => {
  return axiosInstance.delete<HttpResponse<null>>(
    `${RETAILERS.RETAILERS}/${retailer_id}`
  );
};

/**
 * @desc: get all retailers
 * @param params
 * @returns
 */
export const getRetailersList = (params: ListParams) => {
  return axiosInstance.get<any>(RETAILERS.RETAILERS, {
    params,
  });
};

/**
 * @desc: update retailer by id
 * @param id
 * @param updatedBody
 * @returns
 */
export const updateRetailer = (id: string, updatedBody: FormData) => {
  return axiosInstance.put<HttpResponse<null>>(
    `${RETAILERS.RETAILERS}/${id}`,
    updatedBody
  );
};
