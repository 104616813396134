import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ChangePasswordEmailData,
  ChangePasswordWithTokenData,
  LoginData,
} from "models/user-interface";

import {
  changePassword,
  loginUser,
  resetPasswordTokenVerify,
  updateAccessToken,
  verifyEmailPasswordChange,
} from "./api";

/**
 * @desc: login admin
 */
export const loginUserAsync = createAsyncThunk(
  "user/loginUser",
  async (loginInfo: LoginData, { rejectWithValue }) => {
    try {
      const { data } = await loginUser(loginInfo);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: verify admin email for password change
 */
export const verifyEmailForPasswordChange = createAsyncThunk(
  "user/verifyEmailForPasswordChange",
  async (data: ChangePasswordEmailData, { rejectWithValue }) => {
    try {
      const response = await verifyEmailPasswordChange(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: verify reset password token
 */
export const verifyResetPasswordToken = createAsyncThunk(
  "user/verifyResetPasswordToken",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await resetPasswordTokenVerify(token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: change password
 */
export const changePasswordAsync = createAsyncThunk(
  "user/changePasswordAsync",
  async (data: ChangePasswordWithTokenData, { rejectWithValue }) => {
    try {
      const response = await changePassword(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: update access token, when expired using refresh token
 */
export const updateAccessTokenAsync = createAsyncThunk(
  "user/refreshToken",
  async (data: { refreshToken: string }, { rejectWithValue }) => {
    try {
      const response = await updateAccessToken(data);

      return response.data;
    } catch (error) {
      return rejectWithValue({ error: [{ message: [error?.error?.message] }] });
    }
  }
);
