export interface InitialAuthState {
  isLoading: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  isLoggedIn: boolean;
  isPasswordChanged: boolean;
}

const initialAuthState: InitialAuthState = {
  isLoading: false,
  accessToken: null,
  refreshToken: null,
  isLoggedIn: false,
  isPasswordChanged: false,
};

export default initialAuthState;
