import { lazy } from "react";
import BaseLayout from "layouts/BaseLayout";
import { ROUTES } from "constants/routes";
import ChangePassword from "pages/ChangePassword";
import ForgotPassword from "pages/ForgotPassword";

const Login = lazy(() => import("pages/Login"));
const InternalServerError = lazy(
  () => import("components/shared/InternalServerError")
);
const NotFound = lazy(() => import("components/shared/NotFound"));

const authRoutes = [
  {
    path: "/",
    element: <BaseLayout />,
    children: [
      {
        path: "",
        element: <Login />,
      },
      {
        path: ROUTES.LOGIN,
        element: <Login />,
      },
      {
        path: ROUTES.RESET_PASSWORD,
        element: <ChangePassword />,
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
    ],
  },
  {
    path: ROUTES.INTERNAL_SERVER_ERROR,
    element: <InternalServerError />,
  },
  {
    path: ROUTES.NOT_FOUND,
    element: <NotFound />,
  },
];

export default authRoutes;
