import axiosInstance from "api/api-service";
import { HttpResponse } from "models/http-interface";
import { USER } from "constants/api-endpoints";
import {
  ChangePasswordEmailData,
  ChangePasswordWithTokenData,
  LoginData,
  LoginResponse,
} from "models/user-interface";

/**
 * @desc: login user
 * @param body
 * @returns
 */
export const loginUser = (body: LoginData) =>
  axiosInstance.post<HttpResponse<LoginResponse>>(USER.LOGIN, body);

/**
 * @desc: change password
 * @param body
 * @returns
 */
export const changePassword = (body: ChangePasswordWithTokenData) =>
  axiosInstance.put<HttpResponse<null>>(
    USER.CHANGE_PASSWORD,
    { password: body.password },
    { headers: { Authorization: `Bearer ${body.token}` } }
  );

/**
 * @desc: update access token, when expired using refresh token
 * @param body
 * @returns
 */
export const updateAccessToken = (body: { refreshToken: string }) =>
  axiosInstance.post<
    HttpResponse<{ refresh_token: string; access_token: string }>
  >(USER.UPDATE_ACCESS_TOKEN, body);

/**
 * @desc: to verify the reset password token
 * @param token
 * @returns
 */
export const resetPasswordTokenVerify = (token: string) =>
  axiosInstance.get<HttpResponse<null>>(
    `${USER.VERIFY_RESET_PASSWORD_TOKEN}?token=${token}`
  );

/**
 * @desc: verify admin email for password change
 * @param body
 * @returns
 */
export const verifyEmailPasswordChange = (body: ChangePasswordEmailData) =>
  axiosInstance.post<HttpResponse<null>>(USER.CHANGE_PASSWORD, body);
