import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { HttpError } from "models/http-interface";
import { showError, showSuccess } from "utils/toast-alerts";
import { setKey } from "utils/local-storage";
import { formatError } from "utils/http-error-formatter";
import { TOKEN } from "constants/local-storage-keys";

import initialState from "./initial-state";
import {
  verifyEmailForPasswordChange,
  loginUserAsync,
  updateAccessTokenAsync,
} from "./thunk";

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUserAsync.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        showSuccess(payload.message);
        state.accessToken = payload.data.access_token;
        state.refreshToken = payload.data.refresh_token;
        state.isLoggedIn = true;
        setKey(TOKEN, payload.data.access_token);
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.isLoading = false;
        const payload = action.payload as HttpError;
        const errorMsg = formatError(payload.error);
        showError(errorMsg);
      })
      .addCase(verifyEmailForPasswordChange.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyEmailForPasswordChange.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(verifyEmailForPasswordChange.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateAccessTokenAsync.fulfilled, (state, { payload }) => {
        state.accessToken = payload.data.access_token;
        state.refreshToken = payload.data.refresh_token;
      })
      .addCase(PURGE, () => {
        return initialState;
      });
  },
});

export default authSlice.reducer;
