import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { TOKEN } from 'constants/local-storage-keys';
import { ROUTES } from 'constants/routes';
import { isLoggedIn } from 'redux/auth/selector';
import { useAppSelector } from 'redux/redux';
import { getKey } from 'utils/local-storage';

/**
 * The PrivateRoute component is used to protect routes and redirect users to the login page if they
 * are not logged in.
 * @param  - - `PrivateRoute`: The name of the functional component being exported.
 * @returns The PrivateRoute component is returning the children components passed to it.
 */
export const PrivateRoute: FC = ({ children }) => {
    const isLogin = useAppSelector(isLoggedIn);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLogin && !getKey(TOKEN)) {
            navigate(`/${ROUTES.LOGIN}`);
        }
    }, [isLogin]);

    return <>{children}</>;
};
