import { LockOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router";
import LanguageSelector from "components/shared/language-selector/LanguageSelector";
import { ROUTES } from "constants/routes";
import useTranslate from "hooks/useTranslate";
import { removeUser } from "utils/remove-user";

import { AUTH } from "models/auth-interface";
import { useState } from "react";
import ModalComponent from "components/UI/modal/Modal";

function HeaderUserbox() {
  const navigate = useNavigate();
  const [logoutModel, setLogoutModel] = useState(false);

  const { parseUI } = useTranslate();

  const logout = () => {
    removeUser();
    navigate(`/${ROUTES.LOGIN}`);
  };

  const onClick = (value: string) => {
    if (value === AUTH.LOGOUT) return logout();
  };

  return (
    <>
      <LanguageSelector />
      <Box sx={{ m: 1 }} className="top-header-btns">
        <Button
          color="primary"
          fullWidth
          onClick={() => setLogoutModel(true)}
          className="flex-start-item logout"
        >
          <LockOutlined sx={{ mr: 1, fontSize: 20 }} />
          {parseUI("LOGOUT")}
        </Button>
      </Box>
      {logoutModel && (
        <ModalComponent
          open={logoutModel}
          handleClose={() => setLogoutModel(false)}
          onDeleteRetailer={() => onClick(AUTH.LOGOUT)}
          btnText={parseUI("LOGOUT")}
          text={parseUI("LOGOUT")}
          subText={parseUI("LOGOUT_SUB_TEXT")}
        />
      )}
    </>
  );
}

export default HeaderUserbox;
