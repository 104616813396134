export const USER = {
  LOGIN: "/api/v1/admin/login",
  CHANGE_PASSWORD: "/api/v1/admin/reset-password",
  UPDATE_ACCESS_TOKEN: "/api/v1/admin/token",
  VERIFY_RESET_PASSWORD_TOKEN: "/api/v1/admin/reset-password-token-verify",
};

export const RETAILERS = {
  RETAILERS: "/api/v1/admin/retailer",
};
