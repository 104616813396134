import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import Button from "components/UI/Button";
import LoadingButton from "components/UI/LoadingButton";
import InputField from "components/UI/form/InputField";
import { ROUTES } from "constants/routes";
import useTranslate from "hooks/useTranslate";
import { HttpError } from "models/http-interface";
import { useAppDispatch, useAppSelector } from "redux/redux";
import { formatError } from "utils/http-error-formatter";
import { showError, showSuccess } from "utils/toast-alerts";
import { ChangePasswordSchema } from "utils/validation-schema";

import { ChangePasswordData } from "models/user-interface";
import { selectLoading } from "redux/auth/selector";
import {
  changePasswordAsync,
  verifyResetPasswordToken,
} from "redux/auth/thunk";

const eyeIcon = true;
const initialValues = {
  password: "",
  passwordConfirm: "",
};

/**
 * @desc: reset password form
 * @returns
 */
const ResetPassword: React.FC = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const { parseUI } = useTranslate();
  const loading = useAppSelector(selectLoading);

  /**
   * @desc: to verify token(reset password token)
   */
  const verifyToken = async () => {
    try {
      const response = await dispatch(verifyResetPasswordToken(token)).unwrap();

      /** to verify the token */
      if (response) {
        setIsTokenValid(true);
      } else {
        navigate("/");
      }
    } catch (error) {
      /** to display error message */
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
      navigate("/");
    }
  };

  useEffect(() => {
    verifyToken();
  }, [token, navigate]);

  /**
   * @desc: show/hide password
   */
  const changePassVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  /**
   * @desc: Handle form submission
   * @param values
   */
  const handleSubmit = async (values: ChangePasswordData) => {
    try {
      const response = await dispatch(
        changePasswordAsync({ password: values.password, token })
      ).unwrap();

      /** to display toast with message on success */
      if (response.message) {
        showSuccess(response.message);
        navigate("/");
      }
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
      navigate("/");
    }
  };

  /**
   * @desc: on click of cancel button
   */
  const onCancelClick = () => {
    navigate(`/${ROUTES.LOGIN}`);
  };

  return isTokenValid ? (
    <Box
      className="loginWrapper"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        paddingTop: 30,
        paddingBottom: 37,
        paddingLeft: 15,
        paddingRight: 15,
      }}
    >
      <img
        src="https://gdbprodstorage01.blob.core.windows.net/gdb-prod/assets/smile-logo-white.png"
        width={160}
        alt="PartnerPuls"
      />
      <h1 className="main-heading">{parseUI("RESET_PASSWORD")}</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={ChangePasswordSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, dirty }) => (
          <Form className="login-outer change-pass-form">
            <InputField
              name="password"
              type={isPasswordVisible ? "text" : "password"}
              label="NEW_PASSWORD"
              isPasswordVisible={isPasswordVisible}
              changePassVisibility={changePassVisibility}
              eyeIcon={eyeIcon}
              required
            />
            <InputField
              name="passwordConfirm"
              type="password"
              label="CONFIRM_PASSWORD"
              required
            />
            <div className="btn-end">
              <LoadingButton
                type="submit"
                label="RESET_PASSWORD"
                loader={loading}
                className="loading-button"
                disabled={!isValid || !dirty || loading === true}
              />

              <Button type="button" onClick={onCancelClick} label="CANCEL" />
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  ) : null;
};

export default ResetPassword;
