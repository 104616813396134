import { FC, useEffect } from "react";
import { Outlet } from "react-router";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "constants/routes";
import { isLoggedIn } from "redux/auth/selector";
import { useAppSelector } from "redux/redux";

/**
 * The PublicRoutes component redirects to the dashboard if the user is logged in, otherwise it renders
 * the child components.
 */
const PublicRoutes: FC = () => {
  const isLogin = useAppSelector(isLoggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogin) {
      navigate(`/${ROUTES.DASHBOARD}`);
    }
  }, [isLogin]);

  return <Outlet />;
};

export default PublicRoutes;
